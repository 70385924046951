exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artiklar-casino-cosmopol-js": () => import("./../../../src/pages/artiklar/casino-cosmopol.js" /* webpackChunkName: "component---src-pages-artiklar-casino-cosmopol-js" */),
  "component---src-pages-artiklar-casino-julkalender-js": () => import("./../../../src/pages/artiklar/casino-julkalender.js" /* webpackChunkName: "component---src-pages-artiklar-casino-julkalender-js" */),
  "component---src-pages-artiklar-casino-med-samma-agare-js": () => import("./../../../src/pages/artiklar/casino-med-samma-agare.js" /* webpackChunkName: "component---src-pages-artiklar-casino-med-samma-agare-js" */),
  "component---src-pages-artiklar-casino-ordlista-js": () => import("./../../../src/pages/artiklar/casino-ordlista.js" /* webpackChunkName: "component---src-pages-artiklar-casino-ordlista-js" */),
  "component---src-pages-artiklar-casinoguide-for-nyborjare-steg-1-js": () => import("./../../../src/pages/artiklar/casinoguide-for-nyborjare-steg-1.js" /* webpackChunkName: "component---src-pages-artiklar-casinoguide-for-nyborjare-steg-1-js" */),
  "component---src-pages-artiklar-gratis-casino-js": () => import("./../../../src/pages/artiklar/gratis-casino.js" /* webpackChunkName: "component---src-pages-artiklar-gratis-casino-js" */),
  "component---src-pages-artiklar-index-js": () => import("./../../../src/pages/artiklar/index.js" /* webpackChunkName: "component---src-pages-artiklar-index-js" */),
  "component---src-pages-artiklar-jackpottvinnare-js": () => import("./../../../src/pages/artiklar/jackpottvinnare.js" /* webpackChunkName: "component---src-pages-artiklar-jackpottvinnare-js" */),
  "component---src-pages-artiklar-nyborjarguide-till-live-casino-js": () => import("./../../../src/pages/artiklar/nyborjarguide-till-live-casino.js" /* webpackChunkName: "component---src-pages-artiklar-nyborjarguide-till-live-casino-js" */),
  "component---src-pages-artiklar-slots-med-hogst-rtp-js": () => import("./../../../src/pages/artiklar/slots-med-hogst-rtp.js" /* webpackChunkName: "component---src-pages-artiklar-slots-med-hogst-rtp-js" */),
  "component---src-pages-artiklar-spela-casino-ansvarsfullt-js": () => import("./../../../src/pages/artiklar/spela-casino-ansvarsfullt.js" /* webpackChunkName: "component---src-pages-artiklar-spela-casino-ansvarsfullt-js" */),
  "component---src-pages-artiklar-svartlistade-casino-js": () => import("./../../../src/pages/artiklar/svartlistade-casino.js" /* webpackChunkName: "component---src-pages-artiklar-svartlistade-casino-js" */),
  "component---src-pages-artiklar-svensk-spellicens-js": () => import("./../../../src/pages/artiklar/svensk-spellicens.js" /* webpackChunkName: "component---src-pages-artiklar-svensk-spellicens-js" */),
  "component---src-pages-artiklar-vinna-pa-casino-tips-for-att-oka-vinstchanserna-js": () => import("./../../../src/pages/artiklar/vinna-pa-casino-tips-for-att-oka-vinstchanserna.js" /* webpackChunkName: "component---src-pages-artiklar-vinna-pa-casino-tips-for-att-oka-vinstchanserna-js" */),
  "component---src-pages-artiklar-vinstskatt-pa-casinon-online-js": () => import("./../../../src/pages/artiklar/vinstskatt-pa-casinon-online.js" /* webpackChunkName: "component---src-pages-artiklar-vinstskatt-pa-casinon-online-js" */),
  "component---src-pages-betalningsmetoder-apple-pay-js": () => import("./../../../src/pages/betalningsmetoder/apple-pay.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-apple-pay-js" */),
  "component---src-pages-betalningsmetoder-brite-js": () => import("./../../../src/pages/betalningsmetoder/brite.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-brite-js" */),
  "component---src-pages-betalningsmetoder-index-js": () => import("./../../../src/pages/betalningsmetoder/index.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-index-js" */),
  "component---src-pages-betalningsmetoder-mastercard-js": () => import("./../../../src/pages/betalningsmetoder/mastercard.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-mastercard-js" */),
  "component---src-pages-betalningsmetoder-neteller-js": () => import("./../../../src/pages/betalningsmetoder/neteller.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-neteller-js" */),
  "component---src-pages-betalningsmetoder-paypal-js": () => import("./../../../src/pages/betalningsmetoder/paypal.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-paypal-js" */),
  "component---src-pages-betalningsmetoder-paysafecard-js": () => import("./../../../src/pages/betalningsmetoder/paysafecard.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-paysafecard-js" */),
  "component---src-pages-betalningsmetoder-skrill-js": () => import("./../../../src/pages/betalningsmetoder/skrill.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-skrill-js" */),
  "component---src-pages-betalningsmetoder-trustly-js": () => import("./../../../src/pages/betalningsmetoder/trustly.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-trustly-js" */),
  "component---src-pages-betalningsmetoder-visa-js": () => import("./../../../src/pages/betalningsmetoder/visa.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-visa-js" */),
  "component---src-pages-betalningsmetoder-zimpler-js": () => import("./../../../src/pages/betalningsmetoder/zimpler.js" /* webpackChunkName: "component---src-pages-betalningsmetoder-zimpler-js" */),
  "component---src-pages-betting-js": () => import("./../../../src/pages/betting.js" /* webpackChunkName: "component---src-pages-betting-js" */),
  "component---src-pages-bingo-js": () => import("./../../../src/pages/bingo.js" /* webpackChunkName: "component---src-pages-bingo-js" */),
  "component---src-pages-casino-bonusar-index-js": () => import("./../../../src/pages/casino-bonusar/index.js" /* webpackChunkName: "component---src-pages-casino-bonusar-index-js" */),
  "component---src-pages-casino-bonusar-utan-insattning-js": () => import("./../../../src/pages/casino-bonusar/utan-insattning.js" /* webpackChunkName: "component---src-pages-casino-bonusar-utan-insattning-js" */),
  "component---src-pages-casino-bonusar-utan-omsattningskrav-js": () => import("./../../../src/pages/casino-bonusar/utan-omsattningskrav.js" /* webpackChunkName: "component---src-pages-casino-bonusar-utan-omsattningskrav-js" */),
  "component---src-pages-casino-med-bankid-js": () => import("./../../../src/pages/casino-med-bankid.js" /* webpackChunkName: "component---src-pages-casino-med-bankid-js" */),
  "component---src-pages-casino-med-swish-js": () => import("./../../../src/pages/casino-med-swish.js" /* webpackChunkName: "component---src-pages-casino-med-swish-js" */),
  "component---src-pages-casino-snabba-uttag-js": () => import("./../../../src/pages/casino-snabba-uttag.js" /* webpackChunkName: "component---src-pages-casino-snabba-uttag-js" */),
  "component---src-pages-casino-utan-registrering-js": () => import("./../../../src/pages/casino-utan-registrering.js" /* webpackChunkName: "component---src-pages-casino-utan-registrering-js" */),
  "component---src-pages-casinosidor-js": () => import("./../../../src/pages/casinosidor.js" /* webpackChunkName: "component---src-pages-casinosidor-js" */),
  "component---src-pages-casinospel-js": () => import("./../../../src/pages/casinospel.js" /* webpackChunkName: "component---src-pages-casinospel-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-freespins-js": () => import("./../../../src/pages/freespins.js" /* webpackChunkName: "component---src-pages-freespins-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-live-casino-js": () => import("./../../../src/pages/live-casino.js" /* webpackChunkName: "component---src-pages-live-casino-js" */),
  "component---src-pages-mobilcasino-js": () => import("./../../../src/pages/mobilcasino.js" /* webpackChunkName: "component---src-pages-mobilcasino-js" */),
  "component---src-pages-nya-casino-js": () => import("./../../../src/pages/nya-casino.js" /* webpackChunkName: "component---src-pages-nya-casino-js" */),
  "component---src-pages-nyheter-100-free-spins-erbjudande-hos-yoyo-js": () => import("./../../../src/pages/nyheter/100-free-spins-erbjudande-hos-yoyo.js" /* webpackChunkName: "component---src-pages-nyheter-100-free-spins-erbjudande-hos-yoyo-js" */),
  "component---src-pages-nyheter-100-kr-efter-registrering-hos-mr-green-js": () => import("./../../../src/pages/nyheter/100-kr-efter-registrering-hos-mr-green.js" /* webpackChunkName: "component---src-pages-nyheter-100-kr-efter-registrering-hos-mr-green-js" */),
  "component---src-pages-nyheter-100-kr-pa-odds-casino-eller-livespel-js": () => import("./../../../src/pages/nyheter/100-kr-pa-odds-casino-eller-livespel.js" /* webpackChunkName: "component---src-pages-nyheter-100-kr-pa-odds-casino-eller-livespel-js" */),
  "component---src-pages-nyheter-100-omsattningsfria-spins-eller-3000-kr-js": () => import("./../../../src/pages/nyheter/100-omsattningsfria-spins-eller-3000-kr.js" /* webpackChunkName: "component---src-pages-nyheter-100-omsattningsfria-spins-eller-3000-kr-js" */),
  "component---src-pages-nyheter-100-spins-pa-book-of-dead-hos-playojo-js": () => import("./../../../src/pages/nyheter/100-spins-pa-book-of-dead-hos-playojo.js" /* webpackChunkName: "component---src-pages-nyheter-100-spins-pa-book-of-dead-hos-playojo-js" */),
  "component---src-pages-nyheter-271-miljarder-kronor-i-omsattning-under-2023-js": () => import("./../../../src/pages/nyheter/271-miljarder-kronor-i-omsattning-under-2023.js" /* webpackChunkName: "component---src-pages-nyheter-271-miljarder-kronor-i-omsattning-under-2023-js" */),
  "component---src-pages-nyheter-3-x-upp-till-3000-kr-eller-500-kr-ultrabet-hos-hajper-js": () => import("./../../../src/pages/nyheter/3x-upp-till-3000-kr-eller-500-kr-ultrabet-hos-hajper.js" /* webpackChunkName: "component---src-pages-nyheter-3-x-upp-till-3000-kr-eller-500-kr-ultrabet-hos-hajper-js" */),
  "component---src-pages-nyheter-3000-kr-i-bonus-hos-nano-js": () => import("./../../../src/pages/nyheter/3000-kr-i-bonus-hos-nano.js" /* webpackChunkName: "component---src-pages-nyheter-3000-kr-i-bonus-hos-nano-js" */),
  "component---src-pages-nyheter-5-harliga-fordelar-med-spela-com-js": () => import("./../../../src/pages/nyheter/5-harliga-fordelar-med-spela-com.js" /* webpackChunkName: "component---src-pages-nyheter-5-harliga-fordelar-med-spela-com-js" */),
  "component---src-pages-nyheter-50-kr-efter-registreringen-hos-bethard-js": () => import("./../../../src/pages/nyheter/50-kr-efter-registreringen-hos-bethard.js" /* webpackChunkName: "component---src-pages-nyheter-50-kr-efter-registreringen-hos-bethard-js" */),
  "component---src-pages-nyheter-7-roliga-slots-att-spela-pa-under-pask-2024-js": () => import("./../../../src/pages/nyheter/7-roliga-slots-att-spela-pa-under-pask-2024.js" /* webpackChunkName: "component---src-pages-nyheter-7-roliga-slots-att-spela-pa-under-pask-2024-js" */),
  "component---src-pages-nyheter-alltid-enkelt-till-odds-med-no-account-bet-js": () => import("./../../../src/pages/nyheter/alltid-enkelt-till-odds-med-no-account-bet.js" /* webpackChunkName: "component---src-pages-nyheter-alltid-enkelt-till-odds-med-no-account-bet-js" */),
  "component---src-pages-nyheter-anders-lundin-forklarar-varfor-spel-behover-regler-js": () => import("./../../../src/pages/nyheter/anders-lundin-forklarar-varfor-spel-behover-regler.js" /* webpackChunkName: "component---src-pages-nyheter-anders-lundin-forklarar-varfor-spel-behover-regler-js" */),
  "component---src-pages-nyheter-april-hett-just-nu-js": () => import("./../../../src/pages/nyheter/april-hett-just-nu.js" /* webpackChunkName: "component---src-pages-nyheter-april-hett-just-nu-js" */),
  "component---src-pages-nyheter-arets-julkalender-har-comeon-js": () => import("./../../../src/pages/nyheter/arets-julkalender-har-comeon.js" /* webpackChunkName: "component---src-pages-nyheter-arets-julkalender-har-comeon-js" */),
  "component---src-pages-nyheter-arkadspel-och-bonus-upp-till-3000-kr-js": () => import("./../../../src/pages/nyheter/arkadspel-och-bonus-upp-till-3000-kr.js" /* webpackChunkName: "component---src-pages-nyheter-arkadspel-och-bonus-upp-till-3000-kr-js" */),
  "component---src-pages-nyheter-bet-365-s-vd-fick-2-9-miljarder-i-lon-trots-bolagets-forlust-js": () => import("./../../../src/pages/nyheter/bet365-s-vd-fick-2-9-miljarder-i-lon-trots-bolagets-forlust.js" /* webpackChunkName: "component---src-pages-nyheter-bet-365-s-vd-fick-2-9-miljarder-i-lon-trots-bolagets-forlust-js" */),
  "component---src-pages-nyheter-betinia-nytt-och-spannande-casino-betting-js": () => import("./../../../src/pages/nyheter/betinia-nytt-och-spannande-casino-betting.js" /* webpackChunkName: "component---src-pages-nyheter-betinia-nytt-och-spannande-casino-betting-js" */),
  "component---src-pages-nyheter-betsson-fimpens-resa-js": () => import("./../../../src/pages/nyheter/betsson-fimpens-resa.js" /* webpackChunkName: "component---src-pages-nyheter-betsson-fimpens-resa-js" */),
  "component---src-pages-nyheter-betsson-har-presenterat-de-10-storsta-vinsterna-i-april-js": () => import("./../../../src/pages/nyheter/betsson-har-presenterat-de-10-storsta-vinsterna-i-april.js" /* webpackChunkName: "component---src-pages-nyheter-betsson-har-presenterat-de-10-storsta-vinsterna-i-april-js" */),
  "component---src-pages-nyheter-bingo-rimer-ny-lyllo-casino-ambassador-js": () => import("./../../../src/pages/nyheter/bingo-rimer-ny-lyllo-casino-ambassador.js" /* webpackChunkName: "component---src-pages-nyheter-bingo-rimer-ny-lyllo-casino-ambassador-js" */),
  "component---src-pages-nyheter-black-week-bonus-hos-snabbare-js": () => import("./../../../src/pages/nyheter/black-week-bonus-hos-snabbare.js" /* webpackChunkName: "component---src-pages-nyheter-black-week-bonus-hos-snabbare-js" */),
  "component---src-pages-nyheter-bonusuppdatering-hos-betinia-js": () => import("./../../../src/pages/nyheter/bonusuppdatering-hos-betinia.js" /* webpackChunkName: "component---src-pages-nyheter-bonusuppdatering-hos-betinia-js" */),
  "component---src-pages-nyheter-bonusuppdatering-hos-lyllo-casino-js": () => import("./../../../src/pages/nyheter/bonusuppdatering-hos-lyllo-casino.js" /* webpackChunkName: "component---src-pages-nyheter-bonusuppdatering-hos-lyllo-casino-js" */),
  "component---src-pages-nyheter-campobet-laggs-till-pa-alcasino-js": () => import("./../../../src/pages/nyheter/campobet-laggs-till-pa-alcasino.js" /* webpackChunkName: "component---src-pages-nyheter-campobet-laggs-till-pa-alcasino-js" */),
  "component---src-pages-nyheter-carolina-gynning-ny-ambassador-for-lyllo-casino-js": () => import("./../../../src/pages/nyheter/carolina-gynning-ny-ambassador-for-lyllo-casino.js" /* webpackChunkName: "component---src-pages-nyheter-carolina-gynning-ny-ambassador-for-lyllo-casino-js" */),
  "component---src-pages-nyheter-cashmio-lagger-ner-och-gar-over-till-turbo-vegas-js": () => import("./../../../src/pages/nyheter/cashmio-lagger-ner-och-gar-over-till-turbo-vegas.js" /* webpackChunkName: "component---src-pages-nyheter-cashmio-lagger-ner-och-gar-over-till-turbo-vegas-js" */),
  "component---src-pages-nyheter-casino-cosmopol-oppettider-pa-grund-av-okad-onlinekonkurrens-js": () => import("./../../../src/pages/nyheter/casino-cosmopol-oppettider-pa-grund-av-okad-onlinekonkurrens.js" /* webpackChunkName: "component---src-pages-nyheter-casino-cosmopol-oppettider-pa-grund-av-okad-onlinekonkurrens-js" */),
  "component---src-pages-nyheter-casino-gudarna-det-senaste-hos-alcasino-js": () => import("./../../../src/pages/nyheter/casino-gudarna-det-senaste-hos-alcasino.js" /* webpackChunkName: "component---src-pages-nyheter-casino-gudarna-det-senaste-hos-alcasino-js" */),
  "component---src-pages-nyheter-casumo-uppdaterar-sitt-betalningssortiment-js": () => import("./../../../src/pages/nyheter/casumo-uppdaterar-sitt-betalningssortiment.js" /* webpackChunkName: "component---src-pages-nyheter-casumo-uppdaterar-sitt-betalningssortiment-js" */),
  "component---src-pages-nyheter-chanz-utokar-sitt-live-casino-js": () => import("./../../../src/pages/nyheter/chanz-utokar-sitt-live-casino.js" /* webpackChunkName: "component---src-pages-nyheter-chanz-utokar-sitt-live-casino-js" */),
  "component---src-pages-nyheter-comeon-en-stjarna-pa-svenska-spelmarknaden-js": () => import("./../../../src/pages/nyheter/comeon-en-stjarna-pa-svenska-spelmarknaden.js" /* webpackChunkName: "component---src-pages-nyheter-comeon-en-stjarna-pa-svenska-spelmarknaden-js" */),
  "component---src-pages-nyheter-comeon-firar-13-ar-js": () => import("./../../../src/pages/nyheter/comeon-firar-13-ar.js" /* webpackChunkName: "component---src-pages-nyheter-comeon-firar-13-ar-js" */),
  "component---src-pages-nyheter-den-24-februari-stanger-casino-cosmopol-i-malmo-och-goteborg-js": () => import("./../../../src/pages/nyheter/den-24-februari-stanger-casino-cosmopol-i-malmo-och-goteborg.js" /* webpackChunkName: "component---src-pages-nyheter-den-24-februari-stanger-casino-cosmopol-i-malmo-och-goteborg-js" */),
  "component---src-pages-nyheter-det-regnar-valkomsterbjudande-just-nu-js": () => import("./../../../src/pages/nyheter/det-regnar-valkomsterbjudande-just-nu.js" /* webpackChunkName: "component---src-pages-nyheter-det-regnar-valkomsterbjudande-just-nu-js" */),
  "component---src-pages-nyheter-dunder-erbjuder-en-ny-bonus-js": () => import("./../../../src/pages/nyheter/dunder-erbjuder-en-ny-bonus.js" /* webpackChunkName: "component---src-pages-nyheter-dunder-erbjuder-en-ny-bonus-js" */),
  "component---src-pages-nyheter-dunder-gor-comeback-i-sverige-js": () => import("./../../../src/pages/nyheter/dunder-gor-comeback-i-sverige.js" /* webpackChunkName: "component---src-pages-nyheter-dunder-gor-comeback-i-sverige-js" */),
  "component---src-pages-nyheter-em-2024-resultat-schema-odds-och-speltips-js": () => import("./../../../src/pages/nyheter/em-2024-resultat-schema-odds-och-speltips.js" /* webpackChunkName: "component---src-pages-nyheter-em-2024-resultat-schema-odds-och-speltips-js" */),
  "component---src-pages-nyheter-fa-din-insats-dubblerad-upp-till-2500-kr-js": () => import("./../../../src/pages/nyheter/fa-din-insats-dubblerad-upp-till-2500-kr.js" /* webpackChunkName: "component---src-pages-nyheter-fa-din-insats-dubblerad-upp-till-2500-kr-js" */),
  "component---src-pages-nyheter-ff-lanserar-exklusivt-spel-med-playn-go-js": () => import("./../../../src/pages/nyheter/ff-lanserar-exklusivt-spel-med-playn-go.js" /* webpackChunkName: "component---src-pages-nyheter-ff-lanserar-exklusivt-spel-med-playn-go-js" */),
  "component---src-pages-nyheter-flera-klassiska-varumarken-har-lagts-ner-js": () => import("./../../../src/pages/nyheter/flera-klassiska-varumarken-har-lagts-ner.js" /* webpackChunkName: "component---src-pages-nyheter-flera-klassiska-varumarken-har-lagts-ner-js" */),
  "component---src-pages-nyheter-flera-nyheter-hos-luckycasino-js": () => import("./../../../src/pages/nyheter/flera-nyheter-hos-luckycasino.js" /* webpackChunkName: "component---src-pages-nyheter-flera-nyheter-hos-luckycasino-js" */),
  "component---src-pages-nyheter-flera-uppdateringar-hos-bethard-js": () => import("./../../../src/pages/nyheter/flera-uppdateringar-hos-bethard.js" /* webpackChunkName: "component---src-pages-nyheter-flera-uppdateringar-hos-bethard-js" */),
  "component---src-pages-nyheter-frank-casino-och-slotv-ar-tillbaka-js": () => import("./../../../src/pages/nyheter/frank-casino-och-slotv-ar-tillbaka.js" /* webpackChunkName: "component---src-pages-nyheter-frank-casino-och-slotv-ar-tillbaka-js" */),
  "component---src-pages-nyheter-frank-fred-och-klirr-andrar-aldersgransen-js": () => import("./../../../src/pages/nyheter/frank-fred-och-klirr-andrar-aldersgransen.js" /* webpackChunkName: "component---src-pages-nyheter-frank-fred-och-klirr-andrar-aldersgransen-js" */),
  "component---src-pages-nyheter-halloween-stamning-hos-betsson-js": () => import("./../../../src/pages/nyheter/halloween-stamning-hos-betsson.js" /* webpackChunkName: "component---src-pages-nyheter-halloween-stamning-hos-betsson-js" */),
  "component---src-pages-nyheter-harliga-erbjudanden-och-spannande-spel-till-pask-js": () => import("./../../../src/pages/nyheter/harliga-erbjudanden-och-spannande-spel-till-pask.js" /* webpackChunkName: "component---src-pages-nyheter-harliga-erbjudanden-och-spannande-spel-till-pask-js" */),
  "component---src-pages-nyheter-hela-5-spelkategorier-hos-10-bet-js": () => import("./../../../src/pages/nyheter/hela-5-spelkategorier-hos-10bet.js" /* webpackChunkName: "component---src-pages-nyheter-hela-5-spelkategorier-hos-10-bet-js" */),
  "component---src-pages-nyheter-hogt-intresse-for-mega-moolahs-jackpottspel-js": () => import("./../../../src/pages/nyheter/hogt-intresse-for-mega-moolahs-jackpottspel.js" /* webpackChunkName: "component---src-pages-nyheter-hogt-intresse-for-mega-moolahs-jackpottspel-js" */),
  "component---src-pages-nyheter-index-js": () => import("./../../../src/pages/nyheter/index.js" /* webpackChunkName: "component---src-pages-nyheter-index-js" */),
  "component---src-pages-nyheter-intel-extreme-masters-cologne-2023-matcher-odds-tisdag-js": () => import("./../../../src/pages/nyheter/intel-extreme-masters-cologne-2023-matcher-odds-tisdag.js" /* webpackChunkName: "component---src-pages-nyheter-intel-extreme-masters-cologne-2023-matcher-odds-tisdag-js" */),
  "component---src-pages-nyheter-intel-extreme-masters-cologne-2023-slutspel-fredag-js": () => import("./../../../src/pages/nyheter/intel-extreme-masters-cologne-2023-slutspel-fredag.js" /* webpackChunkName: "component---src-pages-nyheter-intel-extreme-masters-cologne-2023-slutspel-fredag-js" */),
  "component---src-pages-nyheter-julkul-pa-race-casino-js": () => import("./../../../src/pages/nyheter/julkul-pa-race-casino.js" /* webpackChunkName: "component---src-pages-nyheter-julkul-pa-race-casino-js" */),
  "component---src-pages-nyheter-just-nu-500-kr-extra-att-spela-for-100-freespins-js": () => import("./../../../src/pages/nyheter/just-nu-500-kr-extra-att-spela-for-100-freespins.js" /* webpackChunkName: "component---src-pages-nyheter-just-nu-500-kr-extra-att-spela-for-100-freespins-js" */),
  "component---src-pages-nyheter-kazoom-casino-gor-entre-js": () => import("./../../../src/pages/nyheter/kazoom-casino-gor-entre.js" /* webpackChunkName: "component---src-pages-nyheter-kazoom-casino-gor-entre-js" */),
  "component---src-pages-nyheter-klirr-com-har-fatt-ett-nytt-utseende-js": () => import("./../../../src/pages/nyheter/klirr-com-har-fatt-ett-nytt-utseende.js" /* webpackChunkName: "component---src-pages-nyheter-klirr-com-har-fatt-ett-nytt-utseende-js" */),
  "component---src-pages-nyheter-leojackpot-ar-nu-over-158-miljoner-js": () => import("./../../../src/pages/nyheter/leojackpot-ar-nu-over-158-miljoner.js" /* webpackChunkName: "component---src-pages-nyheter-leojackpot-ar-nu-over-158-miljoner-js" */),
  "component---src-pages-nyheter-leovegas-group-uppdaterar-sina-svenska-licenser-js": () => import("./../../../src/pages/nyheter/leovegas-group-uppdaterar-sina-svenska-licenser.js" /* webpackChunkName: "component---src-pages-nyheter-leovegas-group-uppdaterar-sina-svenska-licenser-js" */),
  "component---src-pages-nyheter-luckycasino-firar-5-ar-med-en-tillbakablick-pa-sina-storsta-vinster-js": () => import("./../../../src/pages/nyheter/luckycasino-firar-5-ar-med-en-tillbakablick-pa-sina-storsta-vinster.js" /* webpackChunkName: "component---src-pages-nyheter-luckycasino-firar-5-ar-med-en-tillbakablick-pa-sina-storsta-vinster-js" */),
  "component---src-pages-nyheter-lyllobonus-satt-in-200-kr-spela-for-800-kr-js": () => import("./../../../src/pages/nyheter/lyllobonus-satt-in-200-kr-spela-for-800-kr.js" /* webpackChunkName: "component---src-pages-nyheter-lyllobonus-satt-in-200-kr-spela-for-800-kr-js" */),
  "component---src-pages-nyheter-manadens-casino-april-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-april-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-april-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-februari-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-februari-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-februari-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-januari-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-januari-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-januari-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-juli-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-juli-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-juli-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-juni-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-juni-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-juni-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-maj-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-maj-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-maj-2023-js" */),
  "component---src-pages-nyheter-manadens-casino-mars-2023-js": () => import("./../../../src/pages/nyheter/manadens-casino-mars-2023.js" /* webpackChunkName: "component---src-pages-nyheter-manadens-casino-mars-2023-js" */),
  "component---src-pages-nyheter-mangder-av-jackpotspel-hos-hajper-js": () => import("./../../../src/pages/nyheter/mangder-av-jackpotspel-hos-hajper.js" /* webpackChunkName: "component---src-pages-nyheter-mangder-av-jackpotspel-hos-hajper-js" */),
  "component---src-pages-nyheter-matchanalys-intel-extreme-masters-cologne-2023-mandag-js": () => import("./../../../src/pages/nyheter/matchanalys-intel-extreme-masters-cologne-2023-mandag.js" /* webpackChunkName: "component---src-pages-nyheter-matchanalys-intel-extreme-masters-cologne-2023-mandag-js" */),
  "component---src-pages-nyheter-missa-inte-casino-gods-2022-js": () => import("./../../../src/pages/nyheter/missa-inte-casino-gods-2022.js" /* webpackChunkName: "component---src-pages-nyheter-missa-inte-casino-gods-2022-js" */),
  "component---src-pages-nyheter-missa-inte-leojackpot-js": () => import("./../../../src/pages/nyheter/missa-inte-leojackpot.js" /* webpackChunkName: "component---src-pages-nyheter-missa-inte-leojackpot-js" */),
  "component---src-pages-nyheter-mobilautomaten-ersatts-med-lyllo-casino-js": () => import("./../../../src/pages/nyheter/mobilautomaten-ersatts-med-lyllo-casino.js" /* webpackChunkName: "component---src-pages-nyheter-mobilautomaten-ersatts-med-lyllo-casino-js" */),
  "component---src-pages-nyheter-mr-green-med-nytt-omsattningsfritt-erbjudande-js": () => import("./../../../src/pages/nyheter/mr-green-med-nytt-omsattningsfritt-erbjudande.js" /* webpackChunkName: "component---src-pages-nyheter-mr-green-med-nytt-omsattningsfritt-erbjudande-js" */),
  "component---src-pages-nyheter-mr-vegas-nytt-pa-alcasino-js": () => import("./../../../src/pages/nyheter/mr-vegas-nytt-pa-alcasino.js" /* webpackChunkName: "component---src-pages-nyheter-mr-vegas-nytt-pa-alcasino-js" */),
  "component---src-pages-nyheter-mrgreen-bast-i-norden-pa-spel-2020-js": () => import("./../../../src/pages/nyheter/mrgreen-bast-i-norden-pa-spel-2020.js" /* webpackChunkName: "component---src-pages-nyheter-mrgreen-bast-i-norden-pa-spel-2020-js" */),
  "component---src-pages-nyheter-no-account-casino-har-nytt-bonuserbjudande-js": () => import("./../../../src/pages/nyheter/no-account-casino-har-nytt-bonuserbjudande.js" /* webpackChunkName: "component---src-pages-nyheter-no-account-casino-har-nytt-bonuserbjudande-js" */),
  "component---src-pages-nyheter-nordiska-pelaa-finns-nu-pa-alcasino-js": () => import("./../../../src/pages/nyheter/nordiska-pelaa-finns-nu-pa-alcasino.js" /* webpackChunkName: "component---src-pages-nyheter-nordiska-pelaa-finns-nu-pa-alcasino-js" */),
  "component---src-pages-nyheter-nu-2000-kronor-120-freespins-erbjudande-hos-comeon-js": () => import("./../../../src/pages/nyheter/nu-2000-kronor-120-freespins-erbjudande-hos-comeon.js" /* webpackChunkName: "component---src-pages-nyheter-nu-2000-kronor-120-freespins-erbjudande-hos-comeon-js" */),
  "component---src-pages-nyheter-ny-bonus-och-frankly-speaking-show-js": () => import("./../../../src/pages/nyheter/ny-bonus-och-frankly-speaking-show.js" /* webpackChunkName: "component---src-pages-nyheter-ny-bonus-och-frankly-speaking-show-js" */),
  "component---src-pages-nyheter-ny-svensk-betalningstjanst-hos-paf-js": () => import("./../../../src/pages/nyheter/ny-svensk-betalningstjanst-hos-paf.js" /* webpackChunkName: "component---src-pages-nyheter-ny-svensk-betalningstjanst-hos-paf-js" */),
  "component---src-pages-nyheter-ny-undersokning-foraldrars-kunskap-om-spel-om-pengar-js": () => import("./../../../src/pages/nyheter/ny-undersokning-foraldrars-kunskap-om-spel-om-pengar.js" /* webpackChunkName: "component---src-pages-nyheter-ny-undersokning-foraldrars-kunskap-om-spel-om-pengar-js" */),
  "component---src-pages-nyheter-nya-ruby-lounge-exklusivt-for-comeon-brands-js": () => import("./../../../src/pages/nyheter/nya-ruby-lounge-exklusivt-for-comeon-brands.js" /* webpackChunkName: "component---src-pages-nyheter-nya-ruby-lounge-exklusivt-for-comeon-brands-js" */),
  "component---src-pages-nyheter-nya-spel-hos-race-i-november-js": () => import("./../../../src/pages/nyheter/nya-spel-hos-race-i-november.js" /* webpackChunkName: "component---src-pages-nyheter-nya-spel-hos-race-i-november-js" */),
  "component---src-pages-nyheter-nyhet-i-topplistorna-happy-casino-js": () => import("./../../../src/pages/nyheter/nyhet-i-topplistorna-happy-casino.js" /* webpackChunkName: "component---src-pages-nyheter-nyhet-i-topplistorna-happy-casino-js" */),
  "component---src-pages-nyheter-nyhet-i-vara-topplistor-js": () => import("./../../../src/pages/nyheter/nyhet-i-vara-topplistor.js" /* webpackChunkName: "component---src-pages-nyheter-nyhet-i-vara-topplistor-js" */),
  "component---src-pages-nyheter-nylansering-casinot-luckydays-finns-nu-i-sverige-js": () => import("./../../../src/pages/nyheter/nylansering-casinot-luckydays-finns-nu-i-sverige.js" /* webpackChunkName: "component---src-pages-nyheter-nylansering-casinot-luckydays-finns-nu-i-sverige-js" */),
  "component---src-pages-nyheter-nytt-erbjudande-hos-unibet-js": () => import("./../../../src/pages/nyheter/nytt-erbjudande-hos-unibet.js" /* webpackChunkName: "component---src-pages-nyheter-nytt-erbjudande-hos-unibet-js" */),
  "component---src-pages-nyheter-nytt-valkomsterbjudande-fran-maria-casino-js": () => import("./../../../src/pages/nyheter/nytt-valkomsterbjudande-fran-maria-casino.js" /* webpackChunkName: "component---src-pages-nyheter-nytt-valkomsterbjudande-fran-maria-casino-js" */),
  "component---src-pages-nyheter-nytt-valkomsterbjudande-hos-casinostugan-js": () => import("./../../../src/pages/nyheter/nytt-valkomsterbjudande-hos-casinostugan.js" /* webpackChunkName: "component---src-pages-nyheter-nytt-valkomsterbjudande-hos-casinostugan-js" */),
  "component---src-pages-nyheter-pixel-bet-lagger-till-swish-js": () => import("./../../../src/pages/nyheter/pixel-bet-lagger-till-swish.js" /* webpackChunkName: "component---src-pages-nyheter-pixel-bet-lagger-till-swish-js" */),
  "component---src-pages-nyheter-samir-badran-lyllo-casinos-nya-ambassador-js": () => import("./../../../src/pages/nyheter/samir-badran-lyllo-casinos-nya-ambassador.js" /* webpackChunkName: "component---src-pages-nyheter-samir-badran-lyllo-casinos-nya-ambassador-js" */),
  "component---src-pages-nyheter-skillonnet-utokar-betalningssortimentet-js": () => import("./../../../src/pages/nyheter/skillonnet-utokar-betalningssortimentet.js" /* webpackChunkName: "component---src-pages-nyheter-skillonnet-utokar-betalningssortimentet-js" */),
  "component---src-pages-nyheter-soft-2-bet-lanserar-yoyocasino-app-js": () => import("./../../../src/pages/nyheter/soft2bet-lanserar-yoyocasino-app.js" /* webpackChunkName: "component---src-pages-nyheter-soft-2-bet-lanserar-yoyocasino-app-js" */),
  "component---src-pages-nyheter-speedy-casino-lagger-till-swish-js": () => import("./../../../src/pages/nyheter/speedy-casino-lagger-till-swish.js" /* webpackChunkName: "component---src-pages-nyheter-speedy-casino-lagger-till-swish-js" */),
  "component---src-pages-nyheter-spelar-svenskar-pa-casinon-med-en-svensk-licens-js": () => import("./../../../src/pages/nyheter/spelar-svenskar-pa-casinon-med-en-svensk-licens.js" /* webpackChunkName: "component---src-pages-nyheter-spelar-svenskar-pa-casinon-med-en-svensk-licens-js" */),
  "component---src-pages-nyheter-spelgranser-kan-aterinforas-om-smittan-fortsatter-oka-js": () => import("./../../../src/pages/nyheter/spelgranser-kan-aterinforas-om-smittan-fortsatter-oka.js" /* webpackChunkName: "component---src-pages-nyheter-spelgranser-kan-aterinforas-om-smittan-fortsatter-oka-js" */),
  "component---src-pages-nyheter-spelstudion-octoplay-gor-snart-entre-i-sverige-js": () => import("./../../../src/pages/nyheter/spelstudion-octoplay-gor-snart-entre-i-sverige.js" /* webpackChunkName: "component---src-pages-nyheter-spelstudion-octoplay-gor-snart-entre-i-sverige-js" */),
  "component---src-pages-nyheter-sprillans-nya-kassu-nu-pa-alcasino-js": () => import("./../../../src/pages/nyheter/sprillans-nya-kassu-nu-pa-alcasino.js" /* webpackChunkName: "component---src-pages-nyheter-sprillans-nya-kassu-nu-pa-alcasino-js" */),
  "component---src-pages-nyheter-stor-speluppdatering-av-ll-europe-ltd-js": () => import("./../../../src/pages/nyheter/stor-speluppdatering-av-ll-europe-ltd.js" /* webpackChunkName: "component---src-pages-nyheter-stor-speluppdatering-av-ll-europe-ltd-js" */),
  "component---src-pages-nyheter-stor-storre-storst-casino-i-sverige-js": () => import("./../../../src/pages/nyheter/stor-storre-storst-casino-i-sverige.js" /* webpackChunkName: "component---src-pages-nyheter-stor-storre-storst-casino-i-sverige-js" */),
  "component---src-pages-nyheter-stora-jackpottar-i-september-js": () => import("./../../../src/pages/nyheter/stora-jackpottar-i-september.js" /* webpackChunkName: "component---src-pages-nyheter-stora-jackpottar-i-september-js" */),
  "component---src-pages-nyheter-svenska-spel-hela-sveriges-spelbolag-eller-js": () => import("./../../../src/pages/nyheter/svenska-spel-hela-sveriges-spelbolag-eller.js" /* webpackChunkName: "component---src-pages-nyheter-svenska-spel-hela-sveriges-spelbolag-eller-js" */),
  "component---src-pages-nyheter-svenska-spel-sport-casino-ab-lanserar-momang-casino-js": () => import("./../../../src/pages/nyheter/svenska-spel-sport-casino-ab-lanserar-momang-casino.js" /* webpackChunkName: "component---src-pages-nyheter-svenska-spel-sport-casino-ab-lanserar-momang-casino-js" */),
  "component---src-pages-nyheter-testa-betssons-nya-casino-app-js": () => import("./../../../src/pages/nyheter/testa-betssons-nya-casino-app.js" /* webpackChunkName: "component---src-pages-nyheter-testa-betssons-nya-casino-app-js" */),
  "component---src-pages-nyheter-tips-pa-natcasino-med-basta-pask-slots-2023-js": () => import("./../../../src/pages/nyheter/tips-pa-natcasino-med-basta-pask-slots-2023.js" /* webpackChunkName: "component---src-pages-nyheter-tips-pa-natcasino-med-basta-pask-slots-2023-js" */),
  "component---src-pages-nyheter-topp-10-halloween-slots-2021-js": () => import("./../../../src/pages/nyheter/topp-10-halloween-slots-2021.js" /* webpackChunkName: "component---src-pages-nyheter-topp-10-halloween-slots-2021-js" */),
  "component---src-pages-nyheter-triss-i-uppdateringar-js": () => import("./../../../src/pages/nyheter/triss-i-uppdateringar.js" /* webpackChunkName: "component---src-pages-nyheter-triss-i-uppdateringar-js" */),
  "component---src-pages-nyheter-uppdateringar-hos-no-account-casino-js": () => import("./../../../src/pages/nyheter/uppdateringar-hos-no-account-casino.js" /* webpackChunkName: "component---src-pages-nyheter-uppdateringar-hos-no-account-casino-js" */),
  "component---src-pages-nyheter-utokning-av-spelutbudet-hos-fun-casino-js": () => import("./../../../src/pages/nyheter/utokning-av-spelutbudet-hos-fun-casino.js" /* webpackChunkName: "component---src-pages-nyheter-utokning-av-spelutbudet-hos-fun-casino-js" */),
  "component---src-pages-nyheter-yoyocasino-uppdaterar-sitt-bonuserbjudande-js": () => import("./../../../src/pages/nyheter/yoyocasino-uppdaterar-sitt-bonuserbjudande.js" /* webpackChunkName: "component---src-pages-nyheter-yoyocasino-uppdaterar-sitt-bonuserbjudande-js" */),
  "component---src-pages-om-oss-andreas-sjolander-js": () => import("./../../../src/pages/om-oss/andreas-sjolander.js" /* webpackChunkName: "component---src-pages-om-oss-andreas-sjolander-js" */),
  "component---src-pages-om-oss-index-js": () => import("./../../../src/pages/om-oss/index.js" /* webpackChunkName: "component---src-pages-om-oss-index-js" */),
  "component---src-pages-om-oss-kestutis-balcius-js": () => import("./../../../src/pages/om-oss/kestutis-balcius.js" /* webpackChunkName: "component---src-pages-om-oss-kestutis-balcius-js" */),
  "component---src-pages-pay-n-play-casino-js": () => import("./../../../src/pages/pay-n-play-casino.js" /* webpackChunkName: "component---src-pages-pay-n-play-casino-js" */),
  "component---src-pages-recensioner-10-bet-index-js": () => import("./../../../src/pages/recensioner/10bet/index.js" /* webpackChunkName: "component---src-pages-recensioner-10-bet-index-js" */),
  "component---src-pages-recensioner-888-casino-index-js": () => import("./../../../src/pages/recensioner/888-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-888-casino-index-js" */),
  "component---src-pages-recensioner-ahti-games-index-js": () => import("./../../../src/pages/recensioner/ahti-games/index.js" /* webpackChunkName: "component---src-pages-recensioner-ahti-games-index-js" */),
  "component---src-pages-recensioner-bertil-casino-index-js": () => import("./../../../src/pages/recensioner/bertil-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-bertil-casino-index-js" */),
  "component---src-pages-recensioner-betfair-index-js": () => import("./../../../src/pages/recensioner/betfair/index.js" /* webpackChunkName: "component---src-pages-recensioner-betfair-index-js" */),
  "component---src-pages-recensioner-bethard-index-js": () => import("./../../../src/pages/recensioner/bethard/index.js" /* webpackChunkName: "component---src-pages-recensioner-bethard-index-js" */),
  "component---src-pages-recensioner-betinia-index-js": () => import("./../../../src/pages/recensioner/betinia/index.js" /* webpackChunkName: "component---src-pages-recensioner-betinia-index-js" */),
  "component---src-pages-recensioner-betsafe-index-js": () => import("./../../../src/pages/recensioner/betsafe/index.js" /* webpackChunkName: "component---src-pages-recensioner-betsafe-index-js" */),
  "component---src-pages-recensioner-betsson-index-js": () => import("./../../../src/pages/recensioner/betsson/index.js" /* webpackChunkName: "component---src-pages-recensioner-betsson-index-js" */),
  "component---src-pages-recensioner-betway-index-js": () => import("./../../../src/pages/recensioner/betway/index.js" /* webpackChunkName: "component---src-pages-recensioner-betway-index-js" */),
  "component---src-pages-recensioner-bingo-com-index-js": () => import("./../../../src/pages/recensioner/bingo-com/index.js" /* webpackChunkName: "component---src-pages-recensioner-bingo-com-index-js" */),
  "component---src-pages-recensioner-campobet-index-js": () => import("./../../../src/pages/recensioner/campobet/index.js" /* webpackChunkName: "component---src-pages-recensioner-campobet-index-js" */),
  "component---src-pages-recensioner-casino-gods-index-js": () => import("./../../../src/pages/recensioner/casino-gods/index.js" /* webpackChunkName: "component---src-pages-recensioner-casino-gods-index-js" */),
  "component---src-pages-recensioner-casino-planet-index-js": () => import("./../../../src/pages/recensioner/casino-planet/index.js" /* webpackChunkName: "component---src-pages-recensioner-casino-planet-index-js" */),
  "component---src-pages-recensioner-casinocasino-index-js": () => import("./../../../src/pages/recensioner/casinocasino/index.js" /* webpackChunkName: "component---src-pages-recensioner-casinocasino-index-js" */),
  "component---src-pages-recensioner-casinostugan-index-js": () => import("./../../../src/pages/recensioner/casinostugan/index.js" /* webpackChunkName: "component---src-pages-recensioner-casinostugan-index-js" */),
  "component---src-pages-recensioner-casumo-index-js": () => import("./../../../src/pages/recensioner/casumo/index.js" /* webpackChunkName: "component---src-pages-recensioner-casumo-index-js" */),
  "component---src-pages-recensioner-chanz-index-js": () => import("./../../../src/pages/recensioner/chanz/index.js" /* webpackChunkName: "component---src-pages-recensioner-chanz-index-js" */),
  "component---src-pages-recensioner-comeon-index-js": () => import("./../../../src/pages/recensioner/comeon/index.js" /* webpackChunkName: "component---src-pages-recensioner-comeon-index-js" */),
  "component---src-pages-recensioner-duelz-index-js": () => import("./../../../src/pages/recensioner/duelz/index.js" /* webpackChunkName: "component---src-pages-recensioner-duelz-index-js" */),
  "component---src-pages-recensioner-dunder-index-js": () => import("./../../../src/pages/recensioner/dunder/index.js" /* webpackChunkName: "component---src-pages-recensioner-dunder-index-js" */),
  "component---src-pages-recensioner-expekt-index-js": () => import("./../../../src/pages/recensioner/expekt/index.js" /* webpackChunkName: "component---src-pages-recensioner-expekt-index-js" */),
  "component---src-pages-recensioner-fastbet-index-js": () => import("./../../../src/pages/recensioner/fastbet/index.js" /* webpackChunkName: "component---src-pages-recensioner-fastbet-index-js" */),
  "component---src-pages-recensioner-frank-casino-index-js": () => import("./../../../src/pages/recensioner/frank-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-frank-casino-index-js" */),
  "component---src-pages-recensioner-frank-fred-index-js": () => import("./../../../src/pages/recensioner/frank-fred/index.js" /* webpackChunkName: "component---src-pages-recensioner-frank-fred-index-js" */),
  "component---src-pages-recensioner-fun-casino-index-js": () => import("./../../../src/pages/recensioner/fun-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-fun-casino-index-js" */),
  "component---src-pages-recensioner-gogocasino-index-js": () => import("./../../../src/pages/recensioner/gogocasino/index.js" /* webpackChunkName: "component---src-pages-recensioner-gogocasino-index-js" */),
  "component---src-pages-recensioner-guts-index-js": () => import("./../../../src/pages/recensioner/guts/index.js" /* webpackChunkName: "component---src-pages-recensioner-guts-index-js" */),
  "component---src-pages-recensioner-gutsxpress-index-js": () => import("./../../../src/pages/recensioner/gutsxpress/index.js" /* webpackChunkName: "component---src-pages-recensioner-gutsxpress-index-js" */),
  "component---src-pages-recensioner-hajper-index-js": () => import("./../../../src/pages/recensioner/hajper/index.js" /* webpackChunkName: "component---src-pages-recensioner-hajper-index-js" */),
  "component---src-pages-recensioner-happy-casino-index-js": () => import("./../../../src/pages/recensioner/happy-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-happy-casino-index-js" */),
  "component---src-pages-recensioner-hyper-index-js": () => import("./../../../src/pages/recensioner/hyper/index.js" /* webpackChunkName: "component---src-pages-recensioner-hyper-index-js" */),
  "component---src-pages-recensioner-index-js": () => import("./../../../src/pages/recensioner/index.js" /* webpackChunkName: "component---src-pages-recensioner-index-js" */),
  "component---src-pages-recensioner-intercasino-index-js": () => import("./../../../src/pages/recensioner/intercasino/index.js" /* webpackChunkName: "component---src-pages-recensioner-intercasino-index-js" */),
  "component---src-pages-recensioner-interwetten-index-js": () => import("./../../../src/pages/recensioner/interwetten/index.js" /* webpackChunkName: "component---src-pages-recensioner-interwetten-index-js" */),
  "component---src-pages-recensioner-jackpotcity-index-js": () => import("./../../../src/pages/recensioner/jackpotcity/index.js" /* webpackChunkName: "component---src-pages-recensioner-jackpotcity-index-js" */),
  "component---src-pages-recensioner-jackpotjoy-index-js": () => import("./../../../src/pages/recensioner/jackpotjoy/index.js" /* webpackChunkName: "component---src-pages-recensioner-jackpotjoy-index-js" */),
  "component---src-pages-recensioner-jalla-casino-index-js": () => import("./../../../src/pages/recensioner/jalla-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-jalla-casino-index-js" */),
  "component---src-pages-recensioner-kassu-index-js": () => import("./../../../src/pages/recensioner/kassu/index.js" /* webpackChunkName: "component---src-pages-recensioner-kassu-index-js" */),
  "component---src-pages-recensioner-kazoom-index-js": () => import("./../../../src/pages/recensioner/kazoom/index.js" /* webpackChunkName: "component---src-pages-recensioner-kazoom-index-js" */),
  "component---src-pages-recensioner-klirr-index-js": () => import("./../../../src/pages/recensioner/klirr/index.js" /* webpackChunkName: "component---src-pages-recensioner-klirr-index-js" */),
  "component---src-pages-recensioner-knightslots-index-js": () => import("./../../../src/pages/recensioner/knightslots/index.js" /* webpackChunkName: "component---src-pages-recensioner-knightslots-index-js" */),
  "component---src-pages-recensioner-kungaslottet-index-js": () => import("./../../../src/pages/recensioner/kungaslottet/index.js" /* webpackChunkName: "component---src-pages-recensioner-kungaslottet-index-js" */),
  "component---src-pages-recensioner-leovegas-index-js": () => import("./../../../src/pages/recensioner/leovegas/index.js" /* webpackChunkName: "component---src-pages-recensioner-leovegas-index-js" */),
  "component---src-pages-recensioner-lucky-vegas-index-js": () => import("./../../../src/pages/recensioner/lucky-vegas/index.js" /* webpackChunkName: "component---src-pages-recensioner-lucky-vegas-index-js" */),
  "component---src-pages-recensioner-luckycasino-index-js": () => import("./../../../src/pages/recensioner/luckycasino/index.js" /* webpackChunkName: "component---src-pages-recensioner-luckycasino-index-js" */),
  "component---src-pages-recensioner-luckydays-casino-index-js": () => import("./../../../src/pages/recensioner/luckydays-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-luckydays-casino-index-js" */),
  "component---src-pages-recensioner-lyllo-casino-index-js": () => import("./../../../src/pages/recensioner/lyllo-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-lyllo-casino-index-js" */),
  "component---src-pages-recensioner-maria-casino-index-js": () => import("./../../../src/pages/recensioner/maria-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-maria-casino-index-js" */),
  "component---src-pages-recensioner-metal-casino-index-js": () => import("./../../../src/pages/recensioner/metal-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-metal-casino-index-js" */),
  "component---src-pages-recensioner-momang-casino-index-js": () => import("./../../../src/pages/recensioner/momang-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-momang-casino-index-js" */),
  "component---src-pages-recensioner-mr-green-index-js": () => import("./../../../src/pages/recensioner/mr-green/index.js" /* webpackChunkName: "component---src-pages-recensioner-mr-green-index-js" */),
  "component---src-pages-recensioner-mr-vegas-index-js": () => import("./../../../src/pages/recensioner/mr-vegas/index.js" /* webpackChunkName: "component---src-pages-recensioner-mr-vegas-index-js" */),
  "component---src-pages-recensioner-nano-casino-index-js": () => import("./../../../src/pages/recensioner/nano-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-nano-casino-index-js" */),
  "component---src-pages-recensioner-ninja-casino-index-js": () => import("./../../../src/pages/recensioner/ninja-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-ninja-casino-index-js" */),
  "component---src-pages-recensioner-no-account-bet-index-js": () => import("./../../../src/pages/recensioner/no-account-bet/index.js" /* webpackChunkName: "component---src-pages-recensioner-no-account-bet-index-js" */),
  "component---src-pages-recensioner-no-account-casino-index-js": () => import("./../../../src/pages/recensioner/no-account-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-no-account-casino-index-js" */),
  "component---src-pages-recensioner-nordicbet-index-js": () => import("./../../../src/pages/recensioner/nordicbet/index.js" /* webpackChunkName: "component---src-pages-recensioner-nordicbet-index-js" */),
  "component---src-pages-recensioner-nyspins-index-js": () => import("./../../../src/pages/recensioner/nyspins/index.js" /* webpackChunkName: "component---src-pages-recensioner-nyspins-index-js" */),
  "component---src-pages-recensioner-otto-casino-index-js": () => import("./../../../src/pages/recensioner/otto-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-otto-casino-index-js" */),
  "component---src-pages-recensioner-paf-index-js": () => import("./../../../src/pages/recensioner/paf/index.js" /* webpackChunkName: "component---src-pages-recensioner-paf-index-js" */),
  "component---src-pages-recensioner-pelaa-index-js": () => import("./../../../src/pages/recensioner/pelaa/index.js" /* webpackChunkName: "component---src-pages-recensioner-pelaa-index-js" */),
  "component---src-pages-recensioner-pixel-bet-index-js": () => import("./../../../src/pages/recensioner/pixel-bet/index.js" /* webpackChunkName: "component---src-pages-recensioner-pixel-bet-index-js" */),
  "component---src-pages-recensioner-playojo-index-js": () => import("./../../../src/pages/recensioner/playojo/index.js" /* webpackChunkName: "component---src-pages-recensioner-playojo-index-js" */),
  "component---src-pages-recensioner-prank-casino-index-js": () => import("./../../../src/pages/recensioner/prank-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-prank-casino-index-js" */),
  "component---src-pages-recensioner-premier-live-casino-index-js": () => import("./../../../src/pages/recensioner/premier-live-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-premier-live-casino-index-js" */),
  "component---src-pages-recensioner-pronto-casino-index-js": () => import("./../../../src/pages/recensioner/pronto-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-pronto-casino-index-js" */),
  "component---src-pages-recensioner-pronto-live-index-js": () => import("./../../../src/pages/recensioner/pronto-live/index.js" /* webpackChunkName: "component---src-pages-recensioner-pronto-live-index-js" */),
  "component---src-pages-recensioner-race-casino-index-js": () => import("./../../../src/pages/recensioner/race-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-race-casino-index-js" */),
  "component---src-pages-recensioner-rizk-index-js": () => import("./../../../src/pages/recensioner/rizk/index.js" /* webpackChunkName: "component---src-pages-recensioner-rizk-index-js" */),
  "component---src-pages-recensioner-royale-500-index-js": () => import("./../../../src/pages/recensioner/royale-500/index.js" /* webpackChunkName: "component---src-pages-recensioner-royale-500-index-js" */),
  "component---src-pages-recensioner-slotstars-index-js": () => import("./../../../src/pages/recensioner/slotstars/index.js" /* webpackChunkName: "component---src-pages-recensioner-slotstars-index-js" */),
  "component---src-pages-recensioner-slotv-index-js": () => import("./../../../src/pages/recensioner/slotv/index.js" /* webpackChunkName: "component---src-pages-recensioner-slotv-index-js" */),
  "component---src-pages-recensioner-snabbare-index-js": () => import("./../../../src/pages/recensioner/snabbare/index.js" /* webpackChunkName: "component---src-pages-recensioner-snabbare-index-js" */),
  "component---src-pages-recensioner-speedy-bet-index-js": () => import("./../../../src/pages/recensioner/speedy-bet/index.js" /* webpackChunkName: "component---src-pages-recensioner-speedy-bet-index-js" */),
  "component---src-pages-recensioner-speedy-casino-index-js": () => import("./../../../src/pages/recensioner/speedy-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-speedy-casino-index-js" */),
  "component---src-pages-recensioner-spela-com-index-js": () => import("./../../../src/pages/recensioner/spela-com/index.js" /* webpackChunkName: "component---src-pages-recensioner-spela-com-index-js" */),
  "component---src-pages-recensioner-spinlovers-index-js": () => import("./../../../src/pages/recensioner/spinlovers/index.js" /* webpackChunkName: "component---src-pages-recensioner-spinlovers-index-js" */),
  "component---src-pages-recensioner-storspelare-index-js": () => import("./../../../src/pages/recensioner/storspelare/index.js" /* webpackChunkName: "component---src-pages-recensioner-storspelare-index-js" */),
  "component---src-pages-recensioner-supersnabbt-index-js": () => import("./../../../src/pages/recensioner/supersnabbt/index.js" /* webpackChunkName: "component---src-pages-recensioner-supersnabbt-index-js" */),
  "component---src-pages-recensioner-svenska-spel-sport-casino-index-js": () => import("./../../../src/pages/recensioner/svenska-spel-sport-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-svenska-spel-sport-casino-index-js" */),
  "component---src-pages-recensioner-swift-casino-index-js": () => import("./../../../src/pages/recensioner/swift-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-swift-casino-index-js" */),
  "component---src-pages-recensioner-thrills-index-js": () => import("./../../../src/pages/recensioner/thrills/index.js" /* webpackChunkName: "component---src-pages-recensioner-thrills-index-js" */),
  "component---src-pages-recensioner-turbonino-index-js": () => import("./../../../src/pages/recensioner/turbonino/index.js" /* webpackChunkName: "component---src-pages-recensioner-turbonino-index-js" */),
  "component---src-pages-recensioner-turbovegas-index-js": () => import("./../../../src/pages/recensioner/turbovegas/index.js" /* webpackChunkName: "component---src-pages-recensioner-turbovegas-index-js" */),
  "component---src-pages-recensioner-unibet-index-js": () => import("./../../../src/pages/recensioner/unibet/index.js" /* webpackChunkName: "component---src-pages-recensioner-unibet-index-js" */),
  "component---src-pages-recensioner-vera-john-index-js": () => import("./../../../src/pages/recensioner/vera-john/index.js" /* webpackChunkName: "component---src-pages-recensioner-vera-john-index-js" */),
  "component---src-pages-recensioner-videoslots-index-js": () => import("./../../../src/pages/recensioner/videoslots/index.js" /* webpackChunkName: "component---src-pages-recensioner-videoslots-index-js" */),
  "component---src-pages-recensioner-vinnarum-index-js": () => import("./../../../src/pages/recensioner/vinnarum/index.js" /* webpackChunkName: "component---src-pages-recensioner-vinnarum-index-js" */),
  "component---src-pages-recensioner-voodoo-dreams-index-js": () => import("./../../../src/pages/recensioner/voodoo-dreams/index.js" /* webpackChunkName: "component---src-pages-recensioner-voodoo-dreams-index-js" */),
  "component---src-pages-recensioner-william-hill-index-js": () => import("./../../../src/pages/recensioner/william-hill/index.js" /* webpackChunkName: "component---src-pages-recensioner-william-hill-index-js" */),
  "component---src-pages-recensioner-winlandia-index-js": () => import("./../../../src/pages/recensioner/winlandia/index.js" /* webpackChunkName: "component---src-pages-recensioner-winlandia-index-js" */),
  "component---src-pages-recensioner-x-3000-index-js": () => import("./../../../src/pages/recensioner/x3000/index.js" /* webpackChunkName: "component---src-pages-recensioner-x-3000-index-js" */),
  "component---src-pages-recensioner-yako-casino-index-js": () => import("./../../../src/pages/recensioner/yako-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-yako-casino-index-js" */),
  "component---src-pages-recensioner-yeti-casino-index-js": () => import("./../../../src/pages/recensioner/yeti-casino/index.js" /* webpackChunkName: "component---src-pages-recensioner-yeti-casino-index-js" */),
  "component---src-pages-recensioner-yoyocasino-index-js": () => import("./../../../src/pages/recensioner/yoyocasino/index.js" /* webpackChunkName: "component---src-pages-recensioner-yoyocasino-index-js" */),
  "component---src-pages-snabbfakta-alla-allsvenskan-vinnare-js": () => import("./../../../src/pages/snabbfakta/alla-allsvenskan-vinnare.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-allsvenskan-vinnare-js" */),
  "component---src-pages-snabbfakta-alla-em-vinnare-i-fotboll-herrar-js": () => import("./../../../src/pages/snabbfakta/alla-em-vinnare-i-fotboll-herrar.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-em-vinnare-i-fotboll-herrar-js" */),
  "component---src-pages-snabbfakta-alla-nba-vinnare-genom-tiderna-js": () => import("./../../../src/pages/snabbfakta/alla-nba-vinnare-genom-tiderna.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-nba-vinnare-genom-tiderna-js" */),
  "component---src-pages-snabbfakta-alla-shl-vinnare-js": () => import("./../../../src/pages/snabbfakta/alla-shl-vinnare.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-shl-vinnare-js" */),
  "component---src-pages-snabbfakta-alla-super-bowl-vinnare-js": () => import("./../../../src/pages/snabbfakta/alla-super-bowl-vinnare.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-super-bowl-vinnare-js" */),
  "component---src-pages-snabbfakta-alla-vm-vinnare-i-fotboll-herrar-js": () => import("./../../../src/pages/snabbfakta/alla-vm-vinnare-i-fotboll-herrar.js" /* webpackChunkName: "component---src-pages-snabbfakta-alla-vm-vinnare-i-fotboll-herrar-js" */),
  "component---src-pages-snabbfakta-index-js": () => import("./../../../src/pages/snabbfakta/index.js" /* webpackChunkName: "component---src-pages-snabbfakta-index-js" */),
  "component---src-pages-snabbfakta-kandaste-casino-varumarken-i-sverige-js": () => import("./../../../src/pages/snabbfakta/kandaste-casino-varumarken-i-sverige.js" /* webpackChunkName: "component---src-pages-snabbfakta-kandaste-casino-varumarken-i-sverige-js" */),
  "component---src-pages-snabbfakta-mest-spelade-online-slots-i-sverige-js": () => import("./../../../src/pages/snabbfakta/mest-spelade-online-slots-i-sverige.js" /* webpackChunkName: "component---src-pages-snabbfakta-mest-spelade-online-slots-i-sverige-js" */),
  "component---src-pages-snabbfakta-uefa-champions-league-vinnare-herrar-js": () => import("./../../../src/pages/snabbfakta/uefa-champions-league-vinnare-herrar.js" /* webpackChunkName: "component---src-pages-snabbfakta-uefa-champions-league-vinnare-herrar-js" */),
  "component---src-pages-snabbfakta-varldens-basta-schackspelare-genom-tiderna-js": () => import("./../../../src/pages/snabbfakta/varldens-basta-schackspelare-genom-tiderna.js" /* webpackChunkName: "component---src-pages-snabbfakta-varldens-basta-schackspelare-genom-tiderna-js" */),
  "component---src-pages-spelautomater-24-star-dream-js": () => import("./../../../src/pages/spelautomater/24-star-dream.js" /* webpackChunkName: "component---src-pages-spelautomater-24-star-dream-js" */),
  "component---src-pages-spelautomater-3-dancing-monkeys-js": () => import("./../../../src/pages/spelautomater/3-dancing-monkeys.js" /* webpackChunkName: "component---src-pages-spelautomater-3-dancing-monkeys-js" */),
  "component---src-pages-spelautomater-9-lions-js": () => import("./../../../src/pages/spelautomater/9-lions.js" /* webpackChunkName: "component---src-pages-spelautomater-9-lions-js" */),
  "component---src-pages-spelautomater-anderthals-js": () => import("./../../../src/pages/spelautomater/anderthals.js" /* webpackChunkName: "component---src-pages-spelautomater-anderthals-js" */),
  "component---src-pages-spelautomater-ashoka-js": () => import("./../../../src/pages/spelautomater/ashoka.js" /* webpackChunkName: "component---src-pages-spelautomater-ashoka-js" */),
  "component---src-pages-spelautomater-aztec-falls-js": () => import("./../../../src/pages/spelautomater/aztec-falls.js" /* webpackChunkName: "component---src-pages-spelautomater-aztec-falls-js" */),
  "component---src-pages-spelautomater-banana-town-js": () => import("./../../../src/pages/spelautomater/banana-town.js" /* webpackChunkName: "component---src-pages-spelautomater-banana-town-js" */),
  "component---src-pages-spelautomater-bankin-bacon-js": () => import("./../../../src/pages/spelautomater/bankin-bacon.js" /* webpackChunkName: "component---src-pages-spelautomater-bankin-bacon-js" */),
  "component---src-pages-spelautomater-big-bad-wolf-megaways-js": () => import("./../../../src/pages/spelautomater/big-bad-wolf-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-big-bad-wolf-megaways-js" */),
  "component---src-pages-spelautomater-big-bass-bonanza-js": () => import("./../../../src/pages/spelautomater/big-bass-bonanza.js" /* webpackChunkName: "component---src-pages-spelautomater-big-bass-bonanza-js" */),
  "component---src-pages-spelautomater-big-bass-hold-spinner-js": () => import("./../../../src/pages/spelautomater/big-bass-hold-spinner.js" /* webpackChunkName: "component---src-pages-spelautomater-big-bass-hold-spinner-js" */),
  "component---src-pages-spelautomater-black-horse-deluxe-js": () => import("./../../../src/pages/spelautomater/black-horse-deluxe.js" /* webpackChunkName: "component---src-pages-spelautomater-black-horse-deluxe-js" */),
  "component---src-pages-spelautomater-black-river-gold-js": () => import("./../../../src/pages/spelautomater/black-river-gold.js" /* webpackChunkName: "component---src-pages-spelautomater-black-river-gold-js" */),
  "component---src-pages-spelautomater-bonanza-js": () => import("./../../../src/pages/spelautomater/bonanza.js" /* webpackChunkName: "component---src-pages-spelautomater-bonanza-js" */),
  "component---src-pages-spelautomater-bonanza-megaways-js": () => import("./../../../src/pages/spelautomater/bonanza-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-bonanza-megaways-js" */),
  "component---src-pages-spelautomater-book-of-books-js": () => import("./../../../src/pages/spelautomater/book-of-books.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-books-js" */),
  "component---src-pages-spelautomater-book-of-inferno-js": () => import("./../../../src/pages/spelautomater/book-of-inferno.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-inferno-js" */),
  "component---src-pages-spelautomater-book-of-juno-js": () => import("./../../../src/pages/spelautomater/book-of-juno.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-juno-js" */),
  "component---src-pages-spelautomater-book-of-power-js": () => import("./../../../src/pages/spelautomater/book-of-power.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-power-js" */),
  "component---src-pages-spelautomater-book-of-time-js": () => import("./../../../src/pages/spelautomater/book-of-time.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-time-js" */),
  "component---src-pages-spelautomater-book-of-toro-js": () => import("./../../../src/pages/spelautomater/book-of-toro.js" /* webpackChunkName: "component---src-pages-spelautomater-book-of-toro-js" */),
  "component---src-pages-spelautomater-books-bulls-js": () => import("./../../../src/pages/spelautomater/books-bulls.js" /* webpackChunkName: "component---src-pages-spelautomater-books-bulls-js" */),
  "component---src-pages-spelautomater-buffalo-king-megaways-js": () => import("./../../../src/pages/spelautomater/buffalo-king-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-buffalo-king-megaways-js" */),
  "component---src-pages-spelautomater-buffalo-rising-megaways-js": () => import("./../../../src/pages/spelautomater/buffalo-rising-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-buffalo-rising-megaways-js" */),
  "component---src-pages-spelautomater-bugs-money-js": () => import("./../../../src/pages/spelautomater/bugs-money.js" /* webpackChunkName: "component---src-pages-spelautomater-bugs-money-js" */),
  "component---src-pages-spelautomater-bugsys-bar-js": () => import("./../../../src/pages/spelautomater/bugsys-bar.js" /* webpackChunkName: "component---src-pages-spelautomater-bugsys-bar-js" */),
  "component---src-pages-spelautomater-busters-bones-js": () => import("./../../../src/pages/spelautomater/busters-bones.js" /* webpackChunkName: "component---src-pages-spelautomater-busters-bones-js" */),
  "component---src-pages-spelautomater-chicken-drop-js": () => import("./../../../src/pages/spelautomater/chicken-drop.js" /* webpackChunkName: "component---src-pages-spelautomater-chicken-drop-js" */),
  "component---src-pages-spelautomater-chilli-pepe-hot-stacks-js": () => import("./../../../src/pages/spelautomater/chilli-pepe-hot-stacks.js" /* webpackChunkName: "component---src-pages-spelautomater-chilli-pepe-hot-stacks-js" */),
  "component---src-pages-spelautomater-dawn-of-egypt-js": () => import("./../../../src/pages/spelautomater/dawn-of-egypt.js" /* webpackChunkName: "component---src-pages-spelautomater-dawn-of-egypt-js" */),
  "component---src-pages-spelautomater-dead-mans-gold-js": () => import("./../../../src/pages/spelautomater/dead-mans-gold.js" /* webpackChunkName: "component---src-pages-spelautomater-dead-mans-gold-js" */),
  "component---src-pages-spelautomater-dead-or-alive-2-js": () => import("./../../../src/pages/spelautomater/dead-or-alive-2.js" /* webpackChunkName: "component---src-pages-spelautomater-dead-or-alive-2-js" */),
  "component---src-pages-spelautomater-deadwood-js": () => import("./../../../src/pages/spelautomater/deadwood.js" /* webpackChunkName: "component---src-pages-spelautomater-deadwood-js" */),
  "component---src-pages-spelautomater-deco-diamonds-deluxe-js": () => import("./../../../src/pages/spelautomater/deco-diamonds-deluxe.js" /* webpackChunkName: "component---src-pages-spelautomater-deco-diamonds-deluxe-js" */),
  "component---src-pages-spelautomater-dino-pd-js": () => import("./../../../src/pages/spelautomater/dino-pd.js" /* webpackChunkName: "component---src-pages-spelautomater-dino-pd-js" */),
  "component---src-pages-spelautomater-dog-town-deal-js": () => import("./../../../src/pages/spelautomater/dog-town-deal.js" /* webpackChunkName: "component---src-pages-spelautomater-dog-town-deal-js" */),
  "component---src-pages-spelautomater-enchanted-waters-js": () => import("./../../../src/pages/spelautomater/enchanted-waters.js" /* webpackChunkName: "component---src-pages-spelautomater-enchanted-waters-js" */),
  "component---src-pages-spelautomater-esqueleto-explosivo-2-js": () => import("./../../../src/pages/spelautomater/esqueleto-explosivo-2.js" /* webpackChunkName: "component---src-pages-spelautomater-esqueleto-explosivo-2-js" */),
  "component---src-pages-spelautomater-finns-golden-tavern-js": () => import("./../../../src/pages/spelautomater/finns-golden-tavern.js" /* webpackChunkName: "component---src-pages-spelautomater-finns-golden-tavern-js" */),
  "component---src-pages-spelautomater-flowers-js": () => import("./../../../src/pages/spelautomater/flowers.js" /* webpackChunkName: "component---src-pages-spelautomater-flowers-js" */),
  "component---src-pages-spelautomater-football-super-spins-js": () => import("./../../../src/pages/spelautomater/football-super-spins.js" /* webpackChunkName: "component---src-pages-spelautomater-football-super-spins-js" */),
  "component---src-pages-spelautomater-forge-of-olympus-js": () => import("./../../../src/pages/spelautomater/forge-of-olympus.js" /* webpackChunkName: "component---src-pages-spelautomater-forge-of-olympus-js" */),
  "component---src-pages-spelautomater-gates-of-olympus-1000-js": () => import("./../../../src/pages/spelautomater/gates-of-olympus-1000.js" /* webpackChunkName: "component---src-pages-spelautomater-gates-of-olympus-1000-js" */),
  "component---src-pages-spelautomater-gates-of-olympus-js": () => import("./../../../src/pages/spelautomater/gates-of-olympus.js" /* webpackChunkName: "component---src-pages-spelautomater-gates-of-olympus-js" */),
  "component---src-pages-spelautomater-genie-nights-js": () => import("./../../../src/pages/spelautomater/genie-nights.js" /* webpackChunkName: "component---src-pages-spelautomater-genie-nights-js" */),
  "component---src-pages-spelautomater-gerards-gambit-js": () => import("./../../../src/pages/spelautomater/gerards-gambit.js" /* webpackChunkName: "component---src-pages-spelautomater-gerards-gambit-js" */),
  "component---src-pages-spelautomater-giga-jar-js": () => import("./../../../src/pages/spelautomater/giga-jar.js" /* webpackChunkName: "component---src-pages-spelautomater-giga-jar-js" */),
  "component---src-pages-spelautomater-giganimals-gigablox-js": () => import("./../../../src/pages/spelautomater/giganimals-gigablox.js" /* webpackChunkName: "component---src-pages-spelautomater-giganimals-gigablox-js" */),
  "component---src-pages-spelautomater-gods-of-giza-js": () => import("./../../../src/pages/spelautomater/gods-of-giza.js" /* webpackChunkName: "component---src-pages-spelautomater-gods-of-giza-js" */),
  "component---src-pages-spelautomater-gold-blitz-js": () => import("./../../../src/pages/spelautomater/gold-blitz.js" /* webpackChunkName: "component---src-pages-spelautomater-gold-blitz-js" */),
  "component---src-pages-spelautomater-gold-digger-megaways-js": () => import("./../../../src/pages/spelautomater/gold-digger-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-gold-digger-megaways-js" */),
  "component---src-pages-spelautomater-golden-fields-js": () => import("./../../../src/pages/spelautomater/golden-fields.js" /* webpackChunkName: "component---src-pages-spelautomater-golden-fields-js" */),
  "component---src-pages-spelautomater-gonzos-quest-megaways-js": () => import("./../../../src/pages/spelautomater/gonzos-quest-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-gonzos-quest-megaways-js" */),
  "component---src-pages-spelautomater-gordon-ramsay-hells-kitchen-js": () => import("./../../../src/pages/spelautomater/gordon-ramsay-hells-kitchen.js" /* webpackChunkName: "component---src-pages-spelautomater-gordon-ramsay-hells-kitchen-js" */),
  "component---src-pages-spelautomater-hammerfall-js": () => import("./../../../src/pages/spelautomater/hammerfall.js" /* webpackChunkName: "component---src-pages-spelautomater-hammerfall-js" */),
  "component---src-pages-spelautomater-honey-honey-honey-js": () => import("./../../../src/pages/spelautomater/honey-honey-honey.js" /* webpackChunkName: "component---src-pages-spelautomater-honey-honey-honey-js" */),
  "component---src-pages-spelautomater-hot-slot-777-rubies-js": () => import("./../../../src/pages/spelautomater/hot-slot-777-rubies.js" /* webpackChunkName: "component---src-pages-spelautomater-hot-slot-777-rubies-js" */),
  "component---src-pages-spelautomater-icarus-wilds-js": () => import("./../../../src/pages/spelautomater/icarus-wilds.js" /* webpackChunkName: "component---src-pages-spelautomater-icarus-wilds-js" */),
  "component---src-pages-spelautomater-immortal-romance-js": () => import("./../../../src/pages/spelautomater/immortal-romance.js" /* webpackChunkName: "component---src-pages-spelautomater-immortal-romance-js" */),
  "component---src-pages-spelautomater-index-js": () => import("./../../../src/pages/spelautomater/index.js" /* webpackChunkName: "component---src-pages-spelautomater-index-js" */),
  "component---src-pages-spelautomater-jackpot-raiders-js": () => import("./../../../src/pages/spelautomater/jackpot-raiders.js" /* webpackChunkName: "component---src-pages-spelautomater-jackpot-raiders-js" */),
  "component---src-pages-spelautomater-jammin-jars-2-js": () => import("./../../../src/pages/spelautomater/jammin-jars-2.js" /* webpackChunkName: "component---src-pages-spelautomater-jammin-jars-2-js" */),
  "component---src-pages-spelautomater-jammin-jars-js": () => import("./../../../src/pages/spelautomater/jammin-jars.js" /* webpackChunkName: "component---src-pages-spelautomater-jammin-jars-js" */),
  "component---src-pages-spelautomater-katmandu-x-js": () => import("./../../../src/pages/spelautomater/katmandu-x.js" /* webpackChunkName: "component---src-pages-spelautomater-katmandu-x-js" */),
  "component---src-pages-spelautomater-kiss-my-chainsaw-js": () => import("./../../../src/pages/spelautomater/kiss-my-chainsaw.js" /* webpackChunkName: "component---src-pages-spelautomater-kiss-my-chainsaw-js" */),
  "component---src-pages-spelautomater-knights-of-avalon-js": () => import("./../../../src/pages/spelautomater/knights-of-avalon.js" /* webpackChunkName: "component---src-pages-spelautomater-knights-of-avalon-js" */),
  "component---src-pages-spelautomater-legacy-of-dead-js": () => import("./../../../src/pages/spelautomater/legacy-of-dead.js" /* webpackChunkName: "component---src-pages-spelautomater-legacy-of-dead-js" */),
  "component---src-pages-spelautomater-legacy-of-inca-js": () => import("./../../../src/pages/spelautomater/legacy-of-inca.js" /* webpackChunkName: "component---src-pages-spelautomater-legacy-of-inca-js" */),
  "component---src-pages-spelautomater-leprechaun-goes-wild-js": () => import("./../../../src/pages/spelautomater/leprechaun-goes-wild.js" /* webpackChunkName: "component---src-pages-spelautomater-leprechaun-goes-wild-js" */),
  "component---src-pages-spelautomater-lucky-otter-js": () => import("./../../../src/pages/spelautomater/lucky-otter.js" /* webpackChunkName: "component---src-pages-spelautomater-lucky-otter-js" */),
  "component---src-pages-spelautomater-mad-cars-js": () => import("./../../../src/pages/spelautomater/mad-cars.js" /* webpackChunkName: "component---src-pages-spelautomater-mad-cars-js" */),
  "component---src-pages-spelautomater-madame-destiny-megaways-js": () => import("./../../../src/pages/spelautomater/madame-destiny-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-madame-destiny-megaways-js" */),
  "component---src-pages-spelautomater-mayhem-js": () => import("./../../../src/pages/spelautomater/mayhem.js" /* webpackChunkName: "component---src-pages-spelautomater-mayhem-js" */),
  "component---src-pages-spelautomater-mega-fortune-js": () => import("./../../../src/pages/spelautomater/mega-fortune.js" /* webpackChunkName: "component---src-pages-spelautomater-mega-fortune-js" */),
  "component---src-pages-spelautomater-mega-moolah-js": () => import("./../../../src/pages/spelautomater/mega-moolah.js" /* webpackChunkName: "component---src-pages-spelautomater-mega-moolah-js" */),
  "component---src-pages-spelautomater-midas-magic-js": () => import("./../../../src/pages/spelautomater/midas-magic.js" /* webpackChunkName: "component---src-pages-spelautomater-midas-magic-js" */),
  "component---src-pages-spelautomater-mine-melt-js": () => import("./../../../src/pages/spelautomater/mine-melt.js" /* webpackChunkName: "component---src-pages-spelautomater-mine-melt-js" */),
  "component---src-pages-spelautomater-money-cart-3-js": () => import("./../../../src/pages/spelautomater/money-cart-3.js" /* webpackChunkName: "component---src-pages-spelautomater-money-cart-3-js" */),
  "component---src-pages-spelautomater-money-train-3-js": () => import("./../../../src/pages/spelautomater/money-train-3.js" /* webpackChunkName: "component---src-pages-spelautomater-money-train-3-js" */),
  "component---src-pages-spelautomater-monkey-bonanza-js": () => import("./../../../src/pages/spelautomater/monkey-bonanza.js" /* webpackChunkName: "component---src-pages-spelautomater-monkey-bonanza-js" */),
  "component---src-pages-spelautomater-monopoly-megaways-js": () => import("./../../../src/pages/spelautomater/monopoly-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-monopoly-megaways-js" */),
  "component---src-pages-spelautomater-moon-princess-trinity-js": () => import("./../../../src/pages/spelautomater/moon-princess-trinity.js" /* webpackChunkName: "component---src-pages-spelautomater-moon-princess-trinity-js" */),
  "component---src-pages-spelautomater-moriarty-megaways-js": () => import("./../../../src/pages/spelautomater/moriarty-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-moriarty-megaways-js" */),
  "component---src-pages-spelautomater-muertos-multiplier-megaways-js": () => import("./../../../src/pages/spelautomater/muertos-multiplier-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-muertos-multiplier-megaways-js" */),
  "component---src-pages-spelautomater-narcos-mexico-js": () => import("./../../../src/pages/spelautomater/narcos-mexico.js" /* webpackChunkName: "component---src-pages-spelautomater-narcos-mexico-js" */),
  "component---src-pages-spelautomater-nitropolis-3-js": () => import("./../../../src/pages/spelautomater/nitropolis-3.js" /* webpackChunkName: "component---src-pages-spelautomater-nitropolis-3-js" */),
  "component---src-pages-spelautomater-nitropolis-4-js": () => import("./../../../src/pages/spelautomater/nitropolis-4.js" /* webpackChunkName: "component---src-pages-spelautomater-nitropolis-4-js" */),
  "component---src-pages-spelautomater-oceans-treasure-js": () => import("./../../../src/pages/spelautomater/oceans-treasure.js" /* webpackChunkName: "component---src-pages-spelautomater-oceans-treasure-js" */),
  "component---src-pages-spelautomater-over-the-moon-js": () => import("./../../../src/pages/spelautomater/over-the-moon.js" /* webpackChunkName: "component---src-pages-spelautomater-over-the-moon-js" */),
  "component---src-pages-spelautomater-owls-js": () => import("./../../../src/pages/spelautomater/owls.js" /* webpackChunkName: "component---src-pages-spelautomater-owls-js" */),
  "component---src-pages-spelautomater-ozzy-osbourne-js": () => import("./../../../src/pages/spelautomater/ozzy-osbourne.js" /* webpackChunkName: "component---src-pages-spelautomater-ozzy-osbourne-js" */),
  "component---src-pages-spelautomater-pink-elephants-2-js": () => import("./../../../src/pages/spelautomater/pink-elephants-2.js" /* webpackChunkName: "component---src-pages-spelautomater-pink-elephants-2-js" */),
  "component---src-pages-spelautomater-pink-elephants-js": () => import("./../../../src/pages/spelautomater/pink-elephants.js" /* webpackChunkName: "component---src-pages-spelautomater-pink-elephants-js" */),
  "component---src-pages-spelautomater-pirots-3-js": () => import("./../../../src/pages/spelautomater/pirots-3.js" /* webpackChunkName: "component---src-pages-spelautomater-pirots-3-js" */),
  "component---src-pages-spelautomater-pirots-js": () => import("./../../../src/pages/spelautomater/pirots.js" /* webpackChunkName: "component---src-pages-spelautomater-pirots-js" */),
  "component---src-pages-spelautomater-power-of-merlin-megaways-js": () => import("./../../../src/pages/spelautomater/power-of-merlin-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-power-of-merlin-megaways-js" */),
  "component---src-pages-spelautomater-prized-pets-gigablox-js": () => import("./../../../src/pages/spelautomater/prized-pets-gigablox.js" /* webpackChunkName: "component---src-pages-spelautomater-prized-pets-gigablox-js" */),
  "component---src-pages-spelautomater-rabbit-garden-js": () => import("./../../../src/pages/spelautomater/rabbit-garden.js" /* webpackChunkName: "component---src-pages-spelautomater-rabbit-garden-js" */),
  "component---src-pages-spelautomater-rabbit-royale-js": () => import("./../../../src/pages/spelautomater/rabbit-royale.js" /* webpackChunkName: "component---src-pages-spelautomater-rabbit-royale-js" */),
  "component---src-pages-spelautomater-rasputin-megaways-js": () => import("./../../../src/pages/spelautomater/rasputin-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-rasputin-megaways-js" */),
  "component---src-pages-spelautomater-release-the-kraken-2-js": () => import("./../../../src/pages/spelautomater/release-the-kraken-2.js" /* webpackChunkName: "component---src-pages-spelautomater-release-the-kraken-2-js" */),
  "component---src-pages-spelautomater-retro-tapes-js": () => import("./../../../src/pages/spelautomater/retro-tapes.js" /* webpackChunkName: "component---src-pages-spelautomater-retro-tapes-js" */),
  "component---src-pages-spelautomater-road-rage-js": () => import("./../../../src/pages/spelautomater/road-rage.js" /* webpackChunkName: "component---src-pages-spelautomater-road-rage-js" */),
  "component---src-pages-spelautomater-ronins-honour-js": () => import("./../../../src/pages/spelautomater/ronins-honour.js" /* webpackChunkName: "component---src-pages-spelautomater-ronins-honour-js" */),
  "component---src-pages-spelautomater-scrooge-megaways-js": () => import("./../../../src/pages/spelautomater/scrooge-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-scrooge-megaways-js" */),
  "component---src-pages-spelautomater-secret-of-the-stones-max-js": () => import("./../../../src/pages/spelautomater/secret-of-the-stones-max.js" /* webpackChunkName: "component---src-pages-spelautomater-secret-of-the-stones-max-js" */),
  "component---src-pages-spelautomater-sheep-gone-wild-js": () => import("./../../../src/pages/spelautomater/sheep-gone-wild.js" /* webpackChunkName: "component---src-pages-spelautomater-sheep-gone-wild-js" */),
  "component---src-pages-spelautomater-sizzling-eggs-js": () => import("./../../../src/pages/spelautomater/sizzling-eggs.js" /* webpackChunkName: "component---src-pages-spelautomater-sizzling-eggs-js" */),
  "component---src-pages-spelautomater-skulls-up-js": () => import("./../../../src/pages/spelautomater/skulls-up.js" /* webpackChunkName: "component---src-pages-spelautomater-skulls-up-js" */),
  "component---src-pages-spelautomater-slashimi-js": () => import("./../../../src/pages/spelautomater/slashimi.js" /* webpackChunkName: "component---src-pages-spelautomater-slashimi-js" */),
  "component---src-pages-spelautomater-snake-arena-js": () => import("./../../../src/pages/spelautomater/snake-arena.js" /* webpackChunkName: "component---src-pages-spelautomater-snake-arena-js" */),
  "component---src-pages-spelautomater-sonic-links-js": () => import("./../../../src/pages/spelautomater/sonic-links.js" /* webpackChunkName: "component---src-pages-spelautomater-sonic-links-js" */),
  "component---src-pages-spelautomater-sticky-joker-js": () => import("./../../../src/pages/spelautomater/sticky-joker.js" /* webpackChunkName: "component---src-pages-spelautomater-sticky-joker-js" */),
  "component---src-pages-spelautomater-stormforged-js": () => import("./../../../src/pages/spelautomater/stormforged.js" /* webpackChunkName: "component---src-pages-spelautomater-stormforged-js" */),
  "component---src-pages-spelautomater-sunset-js": () => import("./../../../src/pages/spelautomater/sunset.js" /* webpackChunkName: "component---src-pages-spelautomater-sunset-js" */),
  "component---src-pages-spelautomater-super-money-world-js": () => import("./../../../src/pages/spelautomater/super-money-world.js" /* webpackChunkName: "component---src-pages-spelautomater-super-money-world-js" */),
  "component---src-pages-spelautomater-sweet-bonanza-js": () => import("./../../../src/pages/spelautomater/sweet-bonanza.js" /* webpackChunkName: "component---src-pages-spelautomater-sweet-bonanza-js" */),
  "component---src-pages-spelautomater-testament-js": () => import("./../../../src/pages/spelautomater/testament.js" /* webpackChunkName: "component---src-pages-spelautomater-testament-js" */),
  "component---src-pages-spelautomater-the-dog-house-js": () => import("./../../../src/pages/spelautomater/the-dog-house.js" /* webpackChunkName: "component---src-pages-spelautomater-the-dog-house-js" */),
  "component---src-pages-spelautomater-the-goonies-hey-you-guys-js": () => import("./../../../src/pages/spelautomater/the-goonies-hey-you-guys.js" /* webpackChunkName: "component---src-pages-spelautomater-the-goonies-hey-you-guys-js" */),
  "component---src-pages-spelautomater-the-hand-of-midas-js": () => import("./../../../src/pages/spelautomater/the-hand-of-midas.js" /* webpackChunkName: "component---src-pages-spelautomater-the-hand-of-midas-js" */),
  "component---src-pages-spelautomater-the-knight-king-js": () => import("./../../../src/pages/spelautomater/the-knight-king.js" /* webpackChunkName: "component---src-pages-spelautomater-the-knight-king-js" */),
  "component---src-pages-spelautomater-the-red-queen-js": () => import("./../../../src/pages/spelautomater/the-red-queen.js" /* webpackChunkName: "component---src-pages-spelautomater-the-red-queen-js" */),
  "component---src-pages-spelautomater-the-stash-js": () => import("./../../../src/pages/spelautomater/the-stash.js" /* webpackChunkName: "component---src-pages-spelautomater-the-stash-js" */),
  "component---src-pages-spelautomater-the-wildos-js": () => import("./../../../src/pages/spelautomater/the-wildos.js" /* webpackChunkName: "component---src-pages-spelautomater-the-wildos-js" */),
  "component---src-pages-spelautomater-the-wolfs-bane-js": () => import("./../../../src/pages/spelautomater/the-wolfs-bane.js" /* webpackChunkName: "component---src-pages-spelautomater-the-wolfs-bane-js" */),
  "component---src-pages-spelautomater-tiger-tiger-js": () => import("./../../../src/pages/spelautomater/tiger-tiger.js" /* webpackChunkName: "component---src-pages-spelautomater-tiger-tiger-js" */),
  "component---src-pages-spelautomater-undead-fortune-js": () => import("./../../../src/pages/spelautomater/undead-fortune.js" /* webpackChunkName: "component---src-pages-spelautomater-undead-fortune-js" */),
  "component---src-pages-spelautomater-van-gogh-js": () => import("./../../../src/pages/spelautomater/van-gogh.js" /* webpackChunkName: "component---src-pages-spelautomater-van-gogh-js" */),
  "component---src-pages-spelautomater-vikings-go-berzerk-js": () => import("./../../../src/pages/spelautomater/vikings-go-berzerk.js" /* webpackChunkName: "component---src-pages-spelautomater-vikings-go-berzerk-js" */),
  "component---src-pages-spelautomater-wanted-dead-or-a-wild-js": () => import("./../../../src/pages/spelautomater/wanted-dead-or-a-wild.js" /* webpackChunkName: "component---src-pages-spelautomater-wanted-dead-or-a-wild-js" */),
  "component---src-pages-spelautomater-wild-bandolier-js": () => import("./../../../src/pages/spelautomater/wild-bandolier.js" /* webpackChunkName: "component---src-pages-spelautomater-wild-bandolier-js" */),
  "component---src-pages-spelautomater-wild-bison-charge-js": () => import("./../../../src/pages/spelautomater/wild-bison-charge.js" /* webpackChunkName: "component---src-pages-spelautomater-wild-bison-charge-js" */),
  "component---src-pages-spelautomater-wild-celebrity-bus-megaways-js": () => import("./../../../src/pages/spelautomater/wild-celebrity-bus-megaways.js" /* webpackChunkName: "component---src-pages-spelautomater-wild-celebrity-bus-megaways-js" */),
  "component---src-pages-spelautomater-wild-chapo-js": () => import("./../../../src/pages/spelautomater/wild-chapo.js" /* webpackChunkName: "component---src-pages-spelautomater-wild-chapo-js" */),
  "component---src-pages-spelautomater-wild-swarm-js": () => import("./../../../src/pages/spelautomater/wild-swarm.js" /* webpackChunkName: "component---src-pages-spelautomater-wild-swarm-js" */),
  "component---src-pages-spelautomater-wilderland-js": () => import("./../../../src/pages/spelautomater/wilderland.js" /* webpackChunkName: "component---src-pages-spelautomater-wilderland-js" */),
  "component---src-pages-spelautomater-wildfire-wins-extreme-js": () => import("./../../../src/pages/spelautomater/wildfire-wins-extreme.js" /* webpackChunkName: "component---src-pages-spelautomater-wildfire-wins-extreme-js" */),
  "component---src-pages-spelautomater-wins-of-mermaid-multi-power-js": () => import("./../../../src/pages/spelautomater/wins-of-mermaid-multi-power.js" /* webpackChunkName: "component---src-pages-spelautomater-wins-of-mermaid-multi-power-js" */),
  "component---src-pages-spelautomater-worms-reloaded-js": () => import("./../../../src/pages/spelautomater/worms-reloaded.js" /* webpackChunkName: "component---src-pages-spelautomater-worms-reloaded-js" */),
  "component---src-pages-spelautomater-wrigleys-world-js": () => import("./../../../src/pages/spelautomater/wrigleys-world.js" /* webpackChunkName: "component---src-pages-spelautomater-wrigleys-world-js" */),
  "component---src-pages-spelbolag-js": () => import("./../../../src/pages/spelbolag.js" /* webpackChunkName: "component---src-pages-spelbolag-js" */),
  "component---src-pages-speltillverkare-big-time-gaming-index-js": () => import("./../../../src/pages/speltillverkare/big-time-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-big-time-gaming-index-js" */),
  "component---src-pages-speltillverkare-blueprint-gaming-index-js": () => import("./../../../src/pages/speltillverkare/blueprint-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-blueprint-gaming-index-js" */),
  "component---src-pages-speltillverkare-elk-studios-index-js": () => import("./../../../src/pages/speltillverkare/elk-studios/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-elk-studios-index-js" */),
  "component---src-pages-speltillverkare-evolution-index-js": () => import("./../../../src/pages/speltillverkare/evolution/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-evolution-index-js" */),
  "component---src-pages-speltillverkare-fantasma-games-index-js": () => import("./../../../src/pages/speltillverkare/fantasma-games/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-fantasma-games-index-js" */),
  "component---src-pages-speltillverkare-gamomat-index-js": () => import("./../../../src/pages/speltillverkare/gamomat/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-gamomat-index-js" */),
  "component---src-pages-speltillverkare-hacksaw-gaming-index-js": () => import("./../../../src/pages/speltillverkare/hacksaw-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-hacksaw-gaming-index-js" */),
  "component---src-pages-speltillverkare-index-js": () => import("./../../../src/pages/speltillverkare/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-index-js" */),
  "component---src-pages-speltillverkare-isoftbet-index-js": () => import("./../../../src/pages/speltillverkare/isoftbet/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-isoftbet-index-js" */),
  "component---src-pages-speltillverkare-jftw-index-js": () => import("./../../../src/pages/speltillverkare/jftw/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-jftw-index-js" */),
  "component---src-pages-speltillverkare-microgaming-index-js": () => import("./../../../src/pages/speltillverkare/microgaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-microgaming-index-js" */),
  "component---src-pages-speltillverkare-netent-index-js": () => import("./../../../src/pages/speltillverkare/netent/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-netent-index-js" */),
  "component---src-pages-speltillverkare-nolimit-city-index-js": () => import("./../../../src/pages/speltillverkare/nolimit-city/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-nolimit-city-index-js" */),
  "component---src-pages-speltillverkare-northern-lights-index-js": () => import("./../../../src/pages/speltillverkare/northern-lights/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-northern-lights-index-js" */),
  "component---src-pages-speltillverkare-playn-go-index-js": () => import("./../../../src/pages/speltillverkare/playn-go/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-playn-go-index-js" */),
  "component---src-pages-speltillverkare-pragmatic-play-index-js": () => import("./../../../src/pages/speltillverkare/pragmatic-play/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-pragmatic-play-index-js" */),
  "component---src-pages-speltillverkare-push-gaming-index-js": () => import("./../../../src/pages/speltillverkare/push-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-push-gaming-index-js" */),
  "component---src-pages-speltillverkare-quickspin-index-js": () => import("./../../../src/pages/speltillverkare/quickspin/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-quickspin-index-js" */),
  "component---src-pages-speltillverkare-red-tiger-index-js": () => import("./../../../src/pages/speltillverkare/red-tiger/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-red-tiger-index-js" */),
  "component---src-pages-speltillverkare-relax-gaming-index-js": () => import("./../../../src/pages/speltillverkare/relax-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-relax-gaming-index-js" */),
  "component---src-pages-speltillverkare-sthlmgaming-index-js": () => import("./../../../src/pages/speltillverkare/sthlmgaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-sthlmgaming-index-js" */),
  "component---src-pages-speltillverkare-thunderkick-index-js": () => import("./../../../src/pages/speltillverkare/thunderkick/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-thunderkick-index-js" */),
  "component---src-pages-speltillverkare-wazdan-index-js": () => import("./../../../src/pages/speltillverkare/wazdan/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-wazdan-index-js" */),
  "component---src-pages-speltillverkare-yggdrasil-gaming-index-js": () => import("./../../../src/pages/speltillverkare/yggdrasil-gaming/index.js" /* webpackChunkName: "component---src-pages-speltillverkare-yggdrasil-gaming-index-js" */),
  "component---src-pages-svenska-casino-js": () => import("./../../../src/pages/svenska-casino.js" /* webpackChunkName: "component---src-pages-svenska-casino-js" */),
  "component---src-pages-tarningsspel-10000-farkle-js": () => import("./../../../src/pages/tarningsspel/10000-farkle.js" /* webpackChunkName: "component---src-pages-tarningsspel-10000-farkle-js" */),
  "component---src-pages-tarningsspel-blufftarning-js": () => import("./../../../src/pages/tarningsspel/blufftarning.js" /* webpackChunkName: "component---src-pages-tarningsspel-blufftarning-js" */),
  "component---src-pages-tarningsspel-bunco-js": () => import("./../../../src/pages/tarningsspel/bunco.js" /* webpackChunkName: "component---src-pages-tarningsspel-bunco-js" */),
  "component---src-pages-tarningsspel-chicago-dice-js": () => import("./../../../src/pages/tarningsspel/chicago-dice.js" /* webpackChunkName: "component---src-pages-tarningsspel-chicago-dice-js" */),
  "component---src-pages-tarningsspel-craps-js": () => import("./../../../src/pages/tarningsspel/craps.js" /* webpackChunkName: "component---src-pages-tarningsspel-craps-js" */),
  "component---src-pages-tarningsspel-index-js": () => import("./../../../src/pages/tarningsspel/index.js" /* webpackChunkName: "component---src-pages-tarningsspel-index-js" */),
  "component---src-pages-tarningsspel-lightning-dice-js": () => import("./../../../src/pages/tarningsspel/lightning-dice.js" /* webpackChunkName: "component---src-pages-tarningsspel-lightning-dice-js" */),
  "component---src-pages-tarningsspel-poker-dice-js": () => import("./../../../src/pages/tarningsspel/poker-dice.js" /* webpackChunkName: "component---src-pages-tarningsspel-poker-dice-js" */),
  "component---src-pages-tarningsspel-sic-bo-js": () => import("./../../../src/pages/tarningsspel/sic-bo.js" /* webpackChunkName: "component---src-pages-tarningsspel-sic-bo-js" */),
  "component---src-pages-tarningsspel-tenzi-js": () => import("./../../../src/pages/tarningsspel/tenzi.js" /* webpackChunkName: "component---src-pages-tarningsspel-tenzi-js" */),
  "component---src-pages-tarningsspel-yatzy-js": () => import("./../../../src/pages/tarningsspel/yatzy.js" /* webpackChunkName: "component---src-pages-tarningsspel-yatzy-js" */)
}

